const matomoABTests = () => {
  let _paq = (window._paq = window._paq || []);
  _paq.push([
    "AbTesting::create",
    {
      name: "Stefan-about", // you can also use '1' (ID of the experiment) to hide the name
      percentage: 100,
      includedTargets: [
        { attribute: "path", inverted: "0", type: "contains", value: "/about" },
      ],
      excludedTargets: [],
      variations: [
        {
          name: "original",
          activate: function () {
            // usually nothing needs to be done here
            sessionStorage.setItem("abtest", "original");
          },
        },
        {
          name: "variation1",
          activate: function () {
            document
              .getElementById("ab-about-original")
              .classList.add("d-none");
            document.getElementById("ab-about-v1").classList.remove("d-none");
            sessionStorage.setItem("abtest", "v1");
          },
        },
      ],
      trigger: function () {
        return true; // here you can further customize which of your visitors will participate in this experiment
      },
    },
  ]);
};

export default matomoABTests;
