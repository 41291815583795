const matomo = () => {
  let _paq = (window._paq = window._paq || []);
  _paq.push(["setCustomUrl", location.pathname]);
  _paq.push(["setDocumentTitle", location.pathname + " - " + document.title]);
  _paq.push(["setCookieDomain", "*.taxhawk.com"]);
  _paq.push(["setDomains", ["*.taxhawk.com"]]);
  _paq.push(["enableCrossDomainLinking"]);
  _paq.push(["enableLinkTracking"]);
  (function () {
    let u = "https://analytics.taxhawk.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "2"]);
  })();
  _paq.push(["trackPageView"]);
};

export default matomo;
